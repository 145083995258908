import clsx from 'clsx';

const ResourcesTable = ({ blogArray }) => {
  return (
    <div className="[ p-4 mt-6 ][ rounded-xl ][ bg-gray-97 ]">
      <h2 className="[ text-20 md:text-24 ][ mb-3 ]">Table of Contents</h2>
      <ul className="[ text-14 md:text-16 ][ space-y-3 ]">
        {blogArray.map(({ section_heading, href, id, headingNumber }) => {
          return (
            <li key={section_heading}>
              <a href={href} className={clsx(
                '[ text-blue-50 ][ transition hover:underline ]',
                {
                  '[ pl-6 ]' : headingNumber === 3,
                  '[ pl-12 ]' : headingNumber === 4,
                  '[ pl-18 ]' : headingNumber === 5,
                  '[ pl-24 ]' : headingNumber === 6
                }
              )}>
                {id} {section_heading}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ResourcesTable;
