export const HubspotCSS = `
.hs-form-private {
    max-width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 11fr 1fr;
    margin: 12px 0 12px 0;
}

@media (max-width: 767px) {
  .hs-form-private {
      flex-direction: column;
  }
}
  
.hs_email, .hs-email, .hs-fieldtype-text, .field, .hs-form-field {
  width: 100%;
  order: 1;
}

.hs-form-field > label:first-child {
  display: none;
}
  
.hs-form-field label {
    color: var(--text-primary-black);
    font-size: var(--fz-sub-heading);
    line-height: var(--lh-sub-heading);
    font-weight: var(--heading-fw-500);
    display: block;
    margin-top: 8px;
}
  
.hs-form-field input {
    background-color: var(--color-gray);
    height: 44px;
    border-radius: var(--border-radius-md);
    color: var(--text-primary-black);
    font-size: var(--fz-sub-heading);
    line-height: var(--lh-sub-heading);
    font-weight: var(--heading-fw-500);
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    padding: 0 16px;
    outline: none;
    order: 1;
}

  
.hs-richtext {
    font-size: var(--fz-h6-phone);
}
  
.form-columns-1 {
    max-width: 100% !important;
    color: var(--color-silver-extra-dark);
}
  
.hs-fieldtype-booleancheckbox {
    margin-bottom: 22px !important;
    display: block;
}
  
.hs-button {
    height: 44px;
    padding: 0px 20px !important;
    color: var(--bg-secondary-dark) !important;
    font-size: var(--fz-sub-heading) !important;
    line-height: var(--lh-sub-heading) !important;
    font-weight: var(--heading-fw-500) !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.hs-button:hover {
    background: var(--bg-section) !important;
}

@media (max-width: 767px) {
  .hs-button {
      width: 100%;
  }
}
  
.hs-error-msg {
  font-size: 16px;
  line-height: 25.6px;
  color: var(--color-red)!important;
  grid-column-start: 1!important;
  grid-column-end: 4!important;
  order: 4;  
}
  
.hs_error_rollup {
    margin-bottom: 22px;
    color: var(--color-red);
    order: 3;
    grid-column-start: 1;
    grid-column-end: 4;     
}
.hs-submit {
    order: 2;
}
@media screen and (max-width: 767px) {
    .hs-submit {
        background: var(--bg-section);
        padding: 8.21px 8px;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        max-height: 44px;
    }
    .hs-submit > .actions > input {
        display: none;
    }
    .hs-submit > .actions::before {
        content: url('/img/return_keyboard.svg');
        padding-top: 4px;
    }
    .hs-submit > .actions {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        background: white;
        /* border-top-left-radius: 0; */
        /* border-bottom-left-radius: 0; */
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
    }
}
.submitted-message {
    color: var(--text-primary-black);
    margin: 35px 0px;
}
`;

